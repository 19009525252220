












































import { Component, Vue } from "vue-property-decorator";
import Navigation from '@/components/setup/Navigation.vue';

@Component({name: "Setup", components: {Navigation}})
export default class Setup extends Vue {
    protected isOpen = true;

    constructor() {
        super();
    }

    get step(): string {
        return 'step-'+ this.$route.meta.step || 'step-1';
    }

    get activeStep(): number {
        return Number(this.$route.meta.step);
    }
}
