






















import { Component, Vue, Prop } from "vue-property-decorator";

@Component({ name: "Navigation" })
export default class Navigation extends Vue {

    @Prop({default: 1})
    private readonly step!: number;

    constructor() {
        super();
    }

    get isFirst(): boolean {
        return this.$route.meta.step == "1";
    }
    previous() {
        if (this.$route.meta.step != "1") {
            this.$router.push("/setup/" + this.decrementStep());
        }
    }
    next() {
        if (this.$route.meta.step == "5") {
            this.submit();
        } else {

            this.$router.push("/setup/" + this.incrementStep());
        }
    }

    get nextButtonText(): string {
        if (this.$route.meta.step == "5") {
            return "Finish";
        }
        return "Next";
    }

    incrementStep() {
        let stepNumber = this.step;
        return ++stepNumber;
    }

    decrementStep() {
        let stepNumber = this.step;
        return --stepNumber;
    }

    submit() {

        // get all In Progress Quests + Hook
        // get all Waiting Review Quests + Hook
        // get all On Hold Quests + Hook
        // get all Completed Quests + Hook

        // Dashboard -> first time => Loading All Assets

        this.$router.push("/dashboard");
    }
}
